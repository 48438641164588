import React, {useEffect, useState} from "react";
import './style.scss'
import Background from "./Background";
import Main from "./Main";
import {Game} from "../../components/Game";
import Animate from "../../components/Animate";
import {sendData} from "../../service/visits";

export default function App() {
    const [game, setGame] = useState(false)

    const onCallBack = (show) => {
        setGame(show)
    }

    useEffect(() => {
        sendData('webEvent_game_first_page_init')
    }, [])

    return (
        <>
            {!game ? (
                <>
                    <Animate>
                        <Background/>
                        <Main onCallback={onCallBack}/>
                    </Animate>
                </>
            ) : (
                <Game onCallBack={onCallBack}/>
            )}
        </>
    );
}