import {generateCode} from "./helper";
const ITEM = 'customer_id'

export const getCustomerId = () => {
    let customer_id = localStorage.getItem(ITEM)
    if(!customer_id){
        customer_id = generateCode(24)
        setCustomerId(customer_id)
    }
    return customer_id || null
}

export const setCustomerId = (value) => {
    return localStorage.setItem(ITEM, value)
}

export const removeCustomerId = () => {
    return localStorage.removeItem(ITEM)
}