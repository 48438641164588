import * as React from 'react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Routing from './Routing';
import theme from './theme';
import {Hoc} from "./hoc";
import './index.scss'

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <ThemeProvider theme={theme} >
        <Hoc>
            <CssBaseline />
            <Routing />
        </Hoc>
    </ThemeProvider>,
);