import React, {useEffect} from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import sponsor from '../../assets/game/modal/win/sponsor.png'
import placeHolderSponsor from '../../assets_mini/game/modal/win/sponsor.png'
import CloseIcon from '@mui/icons-material/Close';
import {CopyToClipboard} from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import TextField from "@material-ui/core/TextField/TextField";
import Animate from "../Animate";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {sendData} from "../../service/visits";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '480px',
    minWidth: '320px',
    borderRadius: '20px',
};

export const ModalWin = ({onClose}) => {
    const [open, setOpen] = React.useState(true);
    const [copied, setCopied] = React.useState(false);
    const code = 'OLPERS22'
    const closeModal = () => {
        setOpen(false)
        onClose({type: 'email', show: true})
    }

    const share = async (e) => {
        e.preventDefault();
        try{
            await navigator.share({
                title: code,
                text: `Olper's Breakfast Crush Game Play and win. I've just won a discount by playing Olper's Breakfast Crush! You should try it out:`,
                url: window.location.href,
            })
        }catch (e) {
            console.log('Error sharing', e)
        }
    }

    useEffect(() => {
        sendData('webEvent_game_win_page_init')
    }, [])

    return (
        <Modal
            style={{backdropFilter: 'blur(50px)'}}
            open={open}
            disableAutoFocus={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={'modal-win'}>
                <Animate>
                    <Box className={'close-icon'} onClick={() => {
                        setOpen(false)
                        onClose({type: 'win', show: false})
                        sendData('webEvent_game_win_page_close')
                    }}><CloseIcon fontSize={'large'}/></Box>
                    <Box className={'modal-body'}>
                        <Box className={'title'}>
                            <Typography id="modal-modal-title" component="h3">
                                10% OFF
                            </Typography>
                            <Typography id="modal-modal-description" component="h2" sx={{mt: 2}}>
                                on Olper’s Milk 1L & Olper’s Cream 200ml pack
                            </Typography>
                        </Box>
                        <LazyLoadImage
                            className={'sponsor'}
                            src={sponsor}
                            placeholderSrc={placeHolderSponsor}
                            alt="sponsor"
                        />
                        <Box className={'code'}>
                            <TextField value="OLPERS22" InputProps={{disableUnderline: true}}/>
                            <CopyToClipboard text={code}
                                             onCopy={() => {
                                                 setCopied(true)
                                                 sendData('webEvent_game_win_page_copy')
                                             }}>
                                <Button className={copied ? 'copy copied' : 'copy'}
                                        variant="contained">{copied ? 'COPIED' : 'COPY'}</Button>
                            </CopyToClipboard>
                        </Box>
                        <Box className={'footer'}>
                            <Button variant="text" onClick={() => {
                                sendData('webEvent_game_win_page_send_email')
                                closeModal()
                            }}>SEND TO EMAIL</Button>
                            {navigator?.share ?  <Button variant="text" onClick={(e) => {
                                sendData('webEvent_game_win_page_share_email')
                                share(e)
                            }}>SHARE FUN WITH FRIENDS</Button> : ''}
                        </Box>
                    </Box>
                </Animate>
            </Box>
        </Modal>
    );
};