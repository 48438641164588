import Box from "@material-ui/core/Box/Box";
import * as React from "react";
import './style.scss'
import illustration from '../../../assets/home-page/illustration.png'
import stripesbg from '../../../assets/home-page/stripesbg.png'
import logo from '../../../assets/home-page/logo.png'
import placeHolderIllustration from '../../../assets_mini/home-page/illustration.png'
import placeHolderStripesbg from '../../../assets_mini/home-page/stripesbg.png'
import {LazyLoadImage} from "react-lazy-load-image-component";

export default function Background() {
    return (
        <Box className={'banner'}>
            <img className={'logo'} src={logo} />
            <LazyLoadImage
                className={stripesbg}
                src={stripesbg}
                placeholderSrc={placeHolderStripesbg}
                alt={'stripesbg'}
            />
            <LazyLoadImage
                className={'illustration'}
                src={illustration}
                placeholderSrc={placeHolderIllustration}
                alt={'illustration'}
            />
        </Box>
    );
}