import React, {useContext, useEffect} from "react";
import Box from "@mui/material/Box";

import Modal from "@material-ui/core/Modal/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@material-ui/core/TextField/TextField";
import CloseIcon from '@mui/icons-material/Close';
import withStyles from "@material-ui/core/styles/withStyles";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {sendEmail} from "../../api/games";
import {LoadingContext} from "../../hoc/loading";
import {MessageContext} from "../../hoc/messageNotification";
import Animate from "../Animate";
import {sendData} from "../../service/visits";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '480px',
    minWidth: '320px',
    borderRadius: '20px',
};

const UpdatedTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#757575',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#931C23',
        },
        '& .MuiFormHelperText-root': {
            position: 'absolute',
            top: '-40px',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '17px',
            textTransform: 'capitalize'
        },
        '& .MuiOutlinedInput-root': {
            border: 'none',
            '& fieldset': {
                borderColor: '#931C23',
                borderRadius: '8px'
            },
            '&:hover fieldset': {
                border: '2px solid #931C23',
                borderRadius: '8px'

            },
            '&.Mui-focused fieldset': {
                borderColor: '931C23',
                borderRadius: '8px'
            },
        },
    },
})(TextField);

export const ModalEmail = ({onClose}) => {
    const {setLoading} = useContext(LoadingContext);
    const {setErrorMessage} = useContext(MessageContext);
    const [open, setOpen] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState('');

    const onChangeValue = (e) => {
        setError('')
        setEmail(e.target.value)
    }
    const onSubmit = async (e) => {
        try {
            e.preventDefault();
            if (!validateEmail(email)) {
                return setError('The email is not valid. Please try again!')
            }

            // setLoading(true);
            setErrorMessage(null);
            const template = {
                "code": "OLPERS22",
                "discount": "10%",
                "imageUrl": "https://thumbs.dreamstime.com/z/going-online-29102998.jpg",
                "shopperEmail": email,
                "shopperId": "",
                "title": "Olper's"
            }
            // await sendEmail(template)

            sendData('webEvent_game_email_page_send')

            setOpen(false)
            onClose({type: 'end', show: true})
        } catch (e) {
            setErrorMessage(e);
            setOpen(false)
            onClose({type: 'end', show: true})
        }
        setLoading(false);
    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    useEffect(() => {
        sendData('webEvent_game_email_page_init')
    }, [])

    return (
        <Modal
            style={{backdropFilter: 'blur(50px)'}}
            open={open}
            disableAutoFocus={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={'modal-email'}>
                <Animate>
                    <form onSubmit={(e) => onSubmit(e)}>
                        <Box className={'close-icon'} onClick={() => {
                            setOpen(false)
                            sendData('webEvent_game_email_page_close')
                            onClose({type: 'email', show: false})
                        }}><CloseIcon
                            fontSize={'large'}/></Box>
                        <Box className={'modal-body'}>
                            <Box className={'title'}>
                                <Typography id="modal-modal-title" component="h3">
                                    10% OFF
                                </Typography>
                                <Typography id="modal-modal-description" component="h2" sx={{mt: 2}}>
                                    on Olper’s Milk 1L & Olper’s Cream 200ml pack
                                </Typography>
                                <UpdatedTextField
                                    helperText={error?.length ? error : ''}
                                    className={'email'}
                                    InputProps={{
                                        endAdornment: (
                                            error ? <InputAdornment position="start">
                                                <PriorityHighIcon color={'error'}/>
                                            </InputAdornment> : ''

                                        ),
                                    }}
                                    type={"text"}
                                    label="Your email"
                                    onChange={e => onChangeValue(e)}
                                    value={email}
                                    error={error.length ? true : false}
                                    variant="outlined"/>
                            </Box>
                            <Box className={'send'}>
                                <Button type={'submit'} variant="text">SEND</Button>
                            </Box>
                        </Box>
                    </form>
                </Animate>
            </Box>
        </Modal>
    );
};