import React, {useEffect} from "react";
import Box from "@mui/material/Box";

import Modal from "@material-ui/core/Modal/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import Animate from "../Animate";
import illustration from '../../assets/game/modal/end/illustration.png'
import {sendData} from "../../service/visits";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '480px',
    minWidth: '320px',
    borderRadius: '20px',
};

export const ModalEnd = ({onClose}) => {
    const [open, setOpen] = React.useState(true);

    const closeModal = () => {
        setOpen(false);
        onClose({type: 'win', show: false})
    }

    useEffect(() => {
        sendData('webEvent_game_thank_you_page_init')
    }, [])

    return (
        <Modal
            style={{backdropFilter: 'blur(50px)'}}
            open={open}
            disableAutoFocus={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={'modal-end'}>
                <Animate>
                    <Box className={'close-icon'} onClick={() => {
                        setOpen(false)
                        sendData('webEvent_game_thank_you_page_close')
                        onClose({type: 'end', show: false})
                    }}><CloseIcon fontSize={'large'}/></Box>
                    <Box className={'modal-body'}>
                        <Box className={'title'}>
                            <Typography id="modal-modal-title" component="h3">
                                Thank you!
                            </Typography>
                            <Typography id="modal-modal-description" component="h2" sx={{mt: 2}}>
                                The promocode has been sent to your email.
                            </Typography>
                        </Box>
                        <img className={'background'} src={illustration} alt={'illustration'} />
                        <Button variant="text" onClick={() => {
                            sendData('webEvent_game_thank_you_page_done')
                            closeModal()
                        }}>DONE</Button>
                    </Box>
                </Animate>
            </Box>
        </Modal>
    );
};