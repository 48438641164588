import {red} from '@mui/material/colors';
import {createTheme} from '@mui/material/styles';
import Golos from './assets/golostext/Golos-Text_Regular.ttf';

const textColor = '#ffffff'

const theme = createTheme({
    typography: {
        fontFamily: [
            'Golos',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        body1: {
            fontFamily: 'Golos',
        },
        button: {
            fontFamily: 'Golos',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'Golos';
          font-style: normal;
          font-weight: 400;
          src: local('Golos'), local('Golos-Regular'), url(${Golos}) format('ttf');
        }
      `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontStyle: 'normal',
                    color: textColor,
                    textAlign: 'center',
                    fontFamily: 'Golos',
                    fontWeight: '600',
                    fontsize: '50px',
                    background: "url('./assets/home-page/play.png') no-repeat center",
                    width: '100%',
                    backgroundSize: 'contain',
                    filter: 'drop-shadow(0px 3.47222px 3.47222px rgba(0, 0, 0, 0.1))',
                    fontSize: '18px',
                    lineHeight: '22px',
                    height: '80px',
                    marginBottom: '24px',
                    cursor: 'pointer'
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'Golos',
                    fontWeight: '600',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: "#911D23"
        },
        backDrop: {
            backdropFilter: "blur(3px)",
            backgroundColor: 'rgba(0,0,30,0.4)'
        },
    },
});

export default theme;