import Box from "@material-ui/core/Box/Box";
import * as React from "react";
import './style.scss'
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {sendData} from "../../../service/visits";

export default function Main({onCallback}) {
    return (
        <Box className={'main'}>
            <Box className={'description'}>Play the game and win a discount!</Box>
            <Button onClick={() => {
                sendData('webEvent_game_first_page_lets_play')
                onCallback(true)
            }} variant="text">LET’S PLAY!</Button>
            <Box className={'campaign'}>
                {/*<Box sx={{textAlign: 'center'}}>*/}
                    {/*<Typography variant="h5" className={'validation'}>*/}
                        {/*Campaign valid from 24th Oct to 31st Oct*/}
                    {/*</Typography>*/}
                {/*</Box>*/}
                <ol className={'list'}>
                    <li>Play exciting Olper’s Game to win attractive promotions on Olper’s Products</li>
                    <li>Discount coupon applicable on Olper’s Products only</li>
                    <li>Discount сoupon can be redeemed instantly through order now button or on next purchase through
                        coupon code emailed to you
                    </li>
                    <li>It is a limited time activity, ending on Nov 8th</li>
                </ol>
            </Box>
        </Box>
    );
}