import React from "react";
import Box from "@mui/material/Box";

import Modal from "@material-ui/core/Modal/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import Animate from "../Animate";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '480px',
    minWidth: '320px',
    borderRadius: '20px',
};

export const ModalTimer = ({onClose}) => {
    const [open, setOpen] = React.useState(true);

    const closeModal = () => {
        setOpen(false)
        onClose('game-reload')
    }

    return (
        <Modal
            style={{backdropFilter: 'blur(50px)'}}
            open={open}
            disableAutoFocus={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={'modal-timer'}>
                <Animate>
                    <Box className={'close-icon'} onClick={() => {
                        setOpen(false)
                        onClose('game-reload')
                    }}><CloseIcon fontSize={'large'}/></Box>
                    <Box className={'modal-body'}>
                        <Box className={'title'}>
                            <Typography id="modal-modal-title" component="h3">
                                GAME OVER
                            </Typography>
                            <Typography id="modal-modal-description" component="h2" sx={{mt: 2}}>
                                You ran out of time. Please try again.
                            </Typography>
                        </Box>
                        <Button variant="text" onClick={() => closeModal()}>Play again</Button>
                    </Box>
                </Animate>
            </Box>
        </Modal>
    );
};