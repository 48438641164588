import * as React from 'react';
import Box from '@mui/material/Box';
import HomePage from "../pages/HomePage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "../components/ErrorPage";
import './style.scss';

const style = {
    maxWidth: '500px',
    minWidth: '320px',
    position: 'relative',
    margin: '0 auto'
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage/>,
        errorElement: <ErrorPage/>,
    }
]);

export default function Routing() {

    return (
        <Box sx={style}>
            <RouterProvider router={router}/>
        </Box>
    );
}