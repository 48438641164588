import React, {useState, useEffect, useRef} from "react";
import Box from "@mui/material/Box";

import {Modal} from '../../components/Modal'
import './style.scss'
import Animate from "../Animate";
import {sendData} from "../../service/visits";
import {getCustomerId} from "../../service/localStorageCustomerId";

const NODE_ENV = process.env.NODE_ENV
// export const GAME_URL = NODE_ENV === 'development' ? '//localhost:1234' : `//game.olpers.com.pk`;
export const GAME_URL = NODE_ENV === 'development' ? '//localhost:1234' : `//demo-scene.mini-games.tech/`;

export const Game = ({onCallBack}) => {
        const iframeElement = useRef(null);
        const [modal, setModal] = useState({type: 'start', show: false})
        const [showGame, setShowGame] = useState({type: 'start', show: false})

        const sendMessage = () => {
            const gameInfo = {
                bejeweled: {
                    customerId: getCustomerId(),
                    availableGames: 1,
                    period: 'male',
                    sponsors: false
                }
            };

            iframeElement.current.contentWindow.postMessage(gameInfo, '*');
        }

        const reloadGame = () => {
            setShowGame(false)
            setTimeout(() => {
                setShowGame(true)
            }, 1000)
        }

        const modalActive = () => {
            return modal.show === true && modal.type !== 'timer'
        }

        const handleActions = (action) => {
            switch (action) {
                case 'game-won':
                    setTimeout(() => {
                        setModal({type: 'win', show: true})
                    }, 3500)
                    break;
                case 'game-loaded':
                    sendMessage()
                    break;
                case 'timer-finish':
                    if (!modalActive()) {
                        setModal({type: 'timer', show: true})
                    }
                    break;
                case 'game-reload':
                    if (!modalActive()) {
                        reloadGame()
                    }
                    break;
                case 'close':
                    onCallBack(false)
                    break;
                default:

            }
        }

        const onClose = (data) => {
            if (typeof data === 'string') {
                return handleActions(data)
            }

            setModal(data)
        }


        useEffect(() => {
            window.addEventListener('message', event => {
                if (event?.data?.bejeweled?.action) {
                    handleActions(event.data.bejeweled.action)
                }
                if (event?.data?.visits?.name) {
                    const {name, data} = event.data.visits
                    sendData(name, data)
                }
            });
        }, [])

        return (
            <Box
                display={"flex"}
                flexDirection={'column'}
                alignItems={"center"}
            >
                {showGame ? <iframe
                    id={'iframe'}
                    ref={iframeElement}
                    title={'iframeElement'}
                    src={GAME_URL}></iframe> : ''}
                <Animate>
                    <Modal {...modal} iframeElement={iframeElement} onClose={(e) => onClose(e)}/>
                </Animate>
            </Box>
        );
    }
;