import React, {useState, useEffect} from "react";
import {motion} from "framer-motion"

import './style.scss'
import {ModalStart} from "./modal-start";
import {ModalWin} from "./modal-win";
import {ModalEmail} from "./modal-email";
import {ModalTimer} from "./modal-timer";
import {ModalEnd} from "./modal-end";

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};

export const Modal = ({show, iframeElement, type, onClose}) => {
    const [open, setOpen] = useState(true);

    const sendMessageFreez = (data) => {
        const gameInfo = {
            bejeweled: {
                freez: data
            }
        };

        iframeElement.current.contentWindow.postMessage(gameInfo, '*');
    }

    useEffect(() => {
        if (open) {
            sendMessageFreez(true)
        } else {
            sendMessageFreez(false)
        }
    }, [open])

    useEffect(() => {
        setOpen(show)
    }, [show])

    useEffect(() => {
        // if (!open) onClose()
    }, [open])

    return (
        <>
            {open && type === 'start' && <ModalStart onClose={onClose}/>}
            {open && type === 'win' && <ModalWin onClose={onClose}/>}
            {open && type === 'email' && <ModalEmail onClose={onClose}/>}
            {open && type === 'timer' && <ModalTimer onClose={onClose}/>}
            {open && type === 'end' && <ModalEnd onClose={onClose}/>}
        </>
    );
};