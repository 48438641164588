import React, { useEffect, useState } from "react"
import { Alert } from "@material-ui/lab"

export const MessageContext = React.createContext("")

// const alert = {
//     position: "fixed",
//         bottom: "20px",
//         left: "50%",
//         zIndex: "999",
//         transform: "translate(-50%, -50%)",
//         backgroundColor: "rgb(255, 231, 236) !important",
// }
const success = {
    position: "fixed",
        bottom: "20px",
        left: "50%",
        zIndex: "999",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgb(206, 255, 189) !important",
}

export const MessageNotificationHOC = ({ children }) => {
  const [MessageContextData, setMessageContextData] = useState("")
  const [successMessageContextData, setSuccessMessageContextData] = useState()

  useEffect(() => {
    if (MessageContextData) {
      setTimeout(() => {
        setMessageContextData("")
      }, 5000)
    } else if (successMessageContextData) {
      setTimeout(() => {
        setSuccessMessageContextData({})
      }, 5000)
    }
    else return;
  }, [MessageContextData, successMessageContextData])
  return (
    <MessageContext.Provider
      value={{
        errorMessage: MessageContextData,
        setErrorMessage: setMessageContextData,
        successMessage: successMessageContextData,
        setSuccessMessage: setSuccessMessageContextData
      }}
    >
      {children}
      {MessageContextData && typeof MessageContextData === 'string' && (
        <Alert severity="error">
          {" "}
          {MessageContextData.toString()}
        </Alert>
      )}
      {MessageContextData && typeof MessageContextData === 'object' && MessageContextData.message && (
         <Alert severity="error">
          {" "}
          {MessageContextData?.message}
        </Alert>
      )
      }
       {successMessageContextData && typeof successMessageContextData === 'string' && (
        <Alert style={success} >
          {" "}
          {successMessageContextData.toString()}
        </Alert>
      )}
      {successMessageContextData && 
        Object.keys(successMessageContextData).map(e => <Alert style={success}>
          {" "}
          {e}
        </Alert> )} 
    </MessageContext.Provider>
  )
}
