import React from "react";
import Box from "@mui/material/Box";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Modal from "@material-ui/core/Modal/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import bgImg from '../../assets/game/tiles-board-intro.png'
import placeHolderBgImg from '../../assets_mini/game/tiles-board-intro.png'
import Animate from '../Animate'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '480px',
    minWidth: '320px',
    padding: '40px 0 0 0'
};


export const ModalStart = ({onClose}) => {
    const [open, setOpen] = React.useState(true);

    return (
        <Modal
            style={{backdropFilter: 'blur(50px)'}}
            open={open}
            disableAutoFocus={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className={'modal-start'}>
                <Animate>
                    <Box className={'title'}>
                        <Typography id="modal-modal-title" component="h3">
                            swipe a gift and
                        </Typography>
                        <Typography id="modal-modal-description" component="h2" sx={{mt: 2}}>
                            Make 3 in a row!
                        </Typography>
                    </Box>
                    <LazyLoadImage src={bgImg}
                                   placeholderSrc={placeHolderBgImg}
                                   alt="background game image"
                    />
                    <Button variant="text" onClick={() => setOpen(false)}>LET’S PLAY!</Button>
                </Animate>
            </Box>
        </Modal>
    );
};